import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { AppState } from '../../core.store';
import { UserSettingsKinds } from '../user-settings-types';
import { upsertUserSetting } from '../user-settings.actions';
import { fetchDismissibleState } from './dismissible.actions';
import { selectDismissible } from './dismissible.selectors';

/** Input type for DismissibleDirective. */
export type DismissibleConfig = {
  /**
   * The user setting name to use for storing state. Should be unique for each
   * dismissible unless you want to share state between them.
   */
  name: string;
  /**
   * An observable that will cause the host component to be dismissed (hidden)
   * when it emits true, and shown when it emits false.
   */
  dismiss$: Observable<boolean>;
};

/**
 * A directive that can hide or show a component like *ngIf, but the state is
 * persisted in Nucleus user settings. Can be used to allow the user to
 * permanently dismiss a warning message.
 */
@Directive({
  selector: '[bxDismissible]',
  standalone: true,
})
export class DismissibleDirective extends CleanUp implements OnInit {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  /** Configuration for the directive - see docs for DismissibleConfig */
  @Input() bxDismissible!: DismissibleConfig;

  ngOnInit(): void {
    if (!this.bxDismissible?.name || !this.bxDismissible?.dismiss$) {
      throw new Error(
        'You must define "name" and "dismiss$" in the input object: ' +
          JSON.stringify(this.bxDismissible),
      );
    }
    this.store.dispatch(fetchDismissibleState({ name: this.bxDismissible.name }));
    this.store
      .select(selectDismissible(this.bxDismissible.name))
      .pipe(
        map((state) => state != null && !state.loading && !state.dismissed),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((show) => {
        if (show && this.viewContainer.length === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          // This is required for the the attached component to be initialized
          this.cd.markForCheck();
        } else if (!show && this.viewContainer.length > 0) {
          this.viewContainer.clear();
        }
      });

    this.bxDismissible.dismiss$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dismissed) => this.setDismissed(dismissed));
  }

  /**
   * Sets the state of the alert in the user settings store. The alert will be
   * permanently hidden if dismissed is set to true.
   *
   * @param dismissed whether the alert has been dismissed by the user.
   */
  setDismissed(dismissed: boolean): void {
    this.store.dispatch(
      upsertUserSetting({
        name: this.bxDismissible.name,
        kind: UserSettingsKinds.DISMISSIBLE_STATE,
        data: { dismissed },
      }),
    );
  }
}
