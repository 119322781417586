import { createAction, props } from '@ngrx/store';
import { DismissibleState } from './dismissible.model';

export const fetchDismissibleState = createAction(
  '[Dismissible State] Fetch Dismissible State',
  props<{
    name: string;
  }>(),
);

export const setDismissibleState = createAction(
  '[Dismissible State] Set Dismissible State',
  props<{
    name: string;
    state: DismissibleState;
  }>(),
);
