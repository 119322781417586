import { createAction, props } from '@ngrx/store';
import { UserSetting } from '../../../nucleus/v2/models/user-setting.v2.model';
import { UserSettingsKinds } from './user-settings-types';
import { NewUserSettingShareType } from '@geneious/nucleus-api-client';
import { FormState } from './form-state/form-state.model';
import { GridState } from '../../features/grid/grid.interfaces';
import { ViewerState } from '../viewers-state/viewer-state-store/viewers-state.interface';
import { SequenceViewerPreferencesState } from './sequence-viewer-preferences/sequence-viewer-preferences.model';

export const loadInitialUserSettings = createAction('[User Settings] Load Initial User Settings');

export const loadInitialUserSettingsSuccess = createAction(
  '[User Settings] Load Initial User Settings Success',
  props<{
    tablePreferences: UserSetting<GridState>[];
    pipelineOptions: UserSetting<FormState>[];
    viewerStates: UserSetting<ViewerState>[];
    sequenceViewerPreferences: UserSetting<SequenceViewerPreferencesState>[];
  }>(),
);

export const fetchUserSettingFromServer = createAction(
  '[User Settings] Fetch user setting from server',
  props<{ kind: UserSettingsKinds; name: string }>(),
);

export const fetchUserSettingsByKindFromServer = createAction(
  '[User Settings] Fetch user settings by kind from server',
  props<{ kind: UserSettingsKinds }>(),
);

export const fetchUserSettingSuccess = createAction(
  '[User Settings] Fetch user setting success',
  props<{ userSetting: UserSetting }>(),
);

export const fetchUserSettingNotFound = createAction(
  '[User Settings] Fetch user setting not found',
  props<{ kind: UserSettingsKinds; name: string }>(),
);

export const fetchUserSettingsSuccess = createAction(
  '[User Settings] Fetch user settings success',
  props<{ userSettings: UserSetting[]; kind: UserSettingsKinds }>(),
);

export const upsertUserSetting = createAction(
  `[User settings] Upsert`,
  props<{
    kind: UserSettingsKinds;
    name: string;
    data: unknown;
    shareType?: NewUserSettingShareType;
  }>(),
);

/** This seems to be equivalent to "Upsert Success" */
export const upsertUserSettingOnServer = createAction(
  `[User settings] Upsert On Server`,
  props<{ userSetting: UserSetting }>(),
);

export const deleteUserSetting = createAction(`[User settings] Delete`, props<{ id: string }>());
